import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  TextField,
  Container,
  Button,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import Popin from 'components/Popin/index';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import { isZipCodeNotAllowed } from 'utils';
import AddressPopin from './addressPopin';
import diversApi from 'api/diversApi';
import { useSnackbar } from 'notistack';
import { getMessageError } from 'constants/common/errorCode';

const isAddressInvalid = (addressFields, isRequired = true, manual = false) => {
  if (manual && !isRequired) return false;
  if (
    addressFields.find((field) => {
      return field.invalid;
    })
  )
    return true;
  return false;
};

const isFormEmpty = (addressFields) => {
  return !addressFields.find((field) => {
    return (
      field.value !== undefined && field.value !== null && field.value !== ''
    );
  });
};

const AddressForm = (props) => {
  const {
    automaticFieldTitle,
    addressField,
    fields,
    manualFields,
    onChange,
    onConfirm,
    isRequired,
    isOpen,
    secondaryFields,
    secondaryFieldsTitle,
    disabled,
    popin,
    postalPopin,
  } = props;

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [popinOpen, setPopinOpen] = useState(false);
  const [ errorDisplayed, setErrorDisplayed ] = useState(false);
  const [manual, setManual] = useState(false);
  const [openAddressPopin, setOpenAddressPopin] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleClosePopin = () => setPopinOpen(false);

  const getAvailableAddresses = async () => {
    const { length } = inputValue;
    if (length > 4) {
      await fetchAvailableAddresses();
    } else {
      setAvailableAddresses([]);
    }
  };

  const fetchAvailableAddresses = async () => {
    try {
      const availableAddressesResponse = await diversApi.getAvailableAddresses(inputValue);
      setAvailableAddresses(availableAddressesResponse);
    } catch (err) {
      setAvailableAddresses([]);
      if(!errorDisplayed){
        const messageError = err?.status === 400 ? 'common.autocomplete.adresse_input' : getMessageError(err);
        enqueueSnackbar(t(messageError), { variant: 'error', onClose: () => setErrorDisplayed(false)});
        setErrorDisplayed(true);
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getAvailableAddresses();
    setIsLoading(false);
  }, [inputValue]);

  useEffect(() => {
    if (!open) {
      setAvailableAddresses([]);
    }
  }, [open]);

  useEffect(() => {
    const isManual = !isFormEmpty(manualFields);
    setManual(isManual);
  }, [manualFields, value]);

  useEffect(() => {
    if (postalPopin) {
      const zipCodeField = manualFields.find((object) => {
        return object.key === 'contractAddress.zipCode';
      });

      if (isZipCodeNotAllowed(zipCodeField.value)) {
        setPopinOpen(true);
      }
    }
  }, [manualFields]);

  const handleChange = (value) => {
    const { properties } = value;

    if (properties) {
      const address = {
        address1: properties.name,
        address2: '',
        zipCode: properties.postcode,
        city: properties.city,
      };

      onChange(addressField, address);
      setManual(true);

      setValue(null);
    }
  };

  const getOptionLabel = (option) => {
    if (option.properties && option.properties.label)
      return option.properties.label;

    return option;
  };

  const handleOpenAddressPopin = () => {
    setOpenAddressPopin(true);
  };

  const helperText = (field, manual) => {
    if (manual && !isRequired) return;
    else return showErrors && field.invalid && field.helperText;
  };
  const handleError = (field, manual) => {
    if (manual && isRequired) return;
    else return showErrors && field.invalid;
  };

  const buttonOption = (
    <Button
      fullWidth
      onClick={(e) => {
        setManual(true);
        setOpen(false);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Typography variant="body1">
        {t('steps.addressStep.manuallySetAddress')}
      </Typography>
    </Button>
  );

  const renderFields = (fieldsArray, manual = false) => {
    return fieldsArray.map((field, i) => (
      <FormControl fullWidth margin="dense" key={i}>
        {field.type === 'text' && (
          <TextField
            label={field.label}
            variant="filled"
            error={handleError(field, manual)}
            helperText={helperText(field, manual)}
            onChange={(evt) => onChange(field.key, evt.target.value)}
            value={field.value}
            disabled={disabled}
            InputLabelProps={
              field.shrink && {
                shrink: true,
              }
            }
          />
        )}
      </FormControl>
    ));
  };

  const renderAutomaticField = () => {
    return (
      <FormControl fullWidth margin="dense">
        <Autocomplete
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => getOptionLabel(option)}
          options={(() =>{
            console.log('availableAddresses', availableAddresses);
            return availableAddresses;
          })()}
          filterOptions={() => {
            if (availableAddresses.length === 0) {
              return [buttonOption];
            }

            return availableAddresses;
          }}
          loadingText={t('common.loadingText')}
          loading={loading}
          value={value}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            setAvailableAddresses(
              newValue ? [newValue, ...availableAddresses] : availableAddresses
            );
            setValue(newValue);
            handleChange(newValue);
          }}
          // PaperComponent={Link}
          renderInput={(params) => (
            <TextField
              {...params}
              label={automaticFieldTitle}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    );
  };

  const renderSecondaryFields = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Typography variant="subtitle1">{secondaryFieldsTitle}</Typography>
        <div style={{ display: 'flex', gap: 20 }}>
          {secondaryFields.map((field) => {
            return (
              field.type === 'text' && (
                <TextField
                  key={field.key}
                  label={field.label}
                  variant="filled"
                  error={handleError(field, manual)}
                  helperText={helperText(field, manual)}
                  onChange={(evt) => onChange(field.key, evt.target.value)}
                  value={field.value}
                  style={{ flex: 1 }}
                  InputLabelProps={
                    field.shrink && {
                      shrink: true,
                    }
                  }
                />
              )
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Container maxWidth={'sm'}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems="center"
            justifyContent="center"
          >
            <form noValidate autoComplete="off" style={{ width: '100%' }}>
              {manual ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {renderFields(manualFields, true)}

                  {disabled ? (
                    <Button
                      style={{ alignSelf: 'flex-end' }}
                      onClick={() => {
                        handleOpenAddressPopin();
                      }}
                    >
                      {t('steps.addressStep.changeMyAddress')}
                    </Button>
                  ) : (
                    <Button
                      style={{ alignSelf: 'flex-end' }}
                      disabled={disabled}
                      onClick={() => {
                        setManual(false);
                      }}
                    >
                      {t('steps.addressStep.automaticFindAddress')}
                    </Button>
                  )}
                  {secondaryFields && renderSecondaryFields()}
                </div>
              ) : (
                renderAutomaticField()
              )}
            </form>
          </Grid>
        </Grid>
        <NextStepButton
          onClick={() => {
            setShowErrors(true);
            if (
              !isAddressInvalid(fields) &&
              !isAddressInvalid(manualFields, isRequired, true)
            ) {
              onConfirm();
            }
          }}
        />
        {popin && (
          <AddressPopin
            showPopin={openAddressPopin}
            setShowPopin={setOpenAddressPopin}
            automaticFieldTitle={automaticFieldTitle}
            fieldsObject={'personalAddress'}
          />
        )}
      </Container>
      {postalPopin && (
        <Popin
          open={popinOpen}
          onClose={handleClosePopin}
          content={
            <div style={{ padding: 20, textAlign: 'center' }}>
              <Typography variant="h6">
                {t('pno.addressStep.departmentNotCovered')}
              </Typography>
            </div>
          }
          validateButtonLabel={`${t('common.popin.btn_close')}`}
          showCancelButton={false}
          showCloseButton={true}
          onValidate={handleClosePopin}
        />
      )}
    </>
  );
};

AddressForm.propTypes = {
  automaticFieldTitle: PropTypes.string,
  addressField: PropTypes.string,
  fields: PropTypes.array,
  manualFields: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isOpen: PropTypes.bool,
  secondaryFields: PropTypes.array,
  secondaryFieldsTitle: PropTypes.string,
  disabled: PropTypes.bool,
  popin: PropTypes.bool,
  postalPopin: PropTypes.bool,
};

AddressForm.defaultProps = {
  automaticFieldTitle: 'Adresse',
  addressField: 'address',
  fields: [],
  manualFields: [],
  isRequired: true,
  isOpen: false,
  popin: false,
  postalPopin: false,
};

export default AddressForm;
