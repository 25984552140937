import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import ChildItem from 'components/ChildItem';
import ChildrenModal from 'components/ChildrenModal';
import customerApi from 'api/customerApi';
import policyApi from 'api/policyApi';

import { UserContext } from 'contexts/user';
import useStyles from './style';
import {
  getPolicyStatus,
  PAGE_SECTION_SHOW,
  policeStatuses as statuses,
} from 'views/Dashboard/Customer/PolicyPage/helper';
import { LINK_TYPES } from 'constants/policy';
import { getMessageError } from 'constants/common/common';

const Beneficiaries = (props) => {
  const { police, policyNumber, beneficiaries, setBeneficiaries, scrollRef } = props;

  const policyStatus = police ? getPolicyStatus(police) : null;

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user] = React.useContext(UserContext);
  const [userState] = useContext(UserContext);

  const [selectedChild, setSelectedChild] = useState();
  const [children, setchildren] = useState([]);
  const [popinOpen, setPopinOpen] = useState(false);
  const [roommates, setRoommates] = useState([]);
  const [coInsureds, setCoInsured] = useState([]);

  const fetchBenificiaire = async () => {
    try {
      const [ childrenList, roommatesList, coInsuredList ] = await Promise.all([
        customerApi.getChildren(userState.auth, police.customer.customerNumber),
        policyApi.getPersons(userState.auth, police.policyNumber, LINK_TYPES.COLOCATAIRE.code),
        policyApi.getPersons(userState.auth, police.policyNumber, LINK_TYPES.COASSURE.code),
      ]);

      setchildren(childrenList);
      setRoommates(roommatesList);
      setCoInsured(coInsuredList);
    } catch (error) {
      console.log('fetchBenificiaire', error);
      const messageError = getMessageError(error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (police) fetchBenificiaire();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [police, userState]);

  useEffect(() => {
    setBeneficiaries([...children, ...roommates, ...coInsureds]);
  }, [children, roommates, coInsureds]);

  const handleCloseModal = () => {
    setPopinOpen(false);
    setSelectedChild();
  };

  const handleOpenModal = (child) => {
    if (child) {
      setSelectedChild(child);
    }
    setPopinOpen(true);
  };

  const handleValidate = (child, isModification) => {
    if (isModification) {
      modifyPerson(child);
    } else {
      addPerson(child);
    }
  };

  const modifyPerson = async (child) => {
    try {
      const newChild = { ...child, uuid: null };

      if (child?.linkType?.code === LINK_TYPES.ENFANT.code){
        await customerApi.updatePerson(user.auth, user.customerNumber, child.personUuid, newChild);
      } else if ([LINK_TYPES.COLOCATAIRE.code, LINK_TYPES.COASSURE.code].includes(child?.linkType?.code)){
        await policyApi.updatePerson(user.auth, policyNumber, child.personUuid, newChild);
      }
      fetchBenificiaire();
      handleCloseModal();
    } catch (error) {
      console.log('modifyPerson', error);
      const messageError = getMessageError(error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  const addPerson = async (child) => {
    try {
      if (child.linkType.code === LINK_TYPES.ENFANT.code){
        await customerApi.addPerson(user.auth, user.customerNumber, child);
      } else if ([LINK_TYPES.COLOCATAIRE.code, LINK_TYPES.COASSURE.code].includes(child.linkType.code)){
        await policyApi.addPerson(user.auth, policyNumber, child);
      }
      fetchBenificiaire();
      handleCloseModal();
    } catch (error) {
      console.log('addPerson', error);
      const messageError = getMessageError(error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  if ([statuses.ENDED, statuses.CANCELLED].includes(policyStatus)) return <></>;

  return (
    PAGE_SECTION_SHOW[police?.contractType?.code].beneficiaries && (
      <>
        <Grid
          container
          className={classes.beneficiariesWrapper}
          ref={scrollRef}
        >
          <Box p={2} className={classes.container} width="100%">
            <Grid container item xs={12}>
              <Box mb={2}>
                <Typography variant="h5" color="secondary">
                  {t('dashboard.police.beneficiaries.title')}
                </Typography>
              </Box>
              <Grid item container>
                {beneficiaries.map((child) => (
                  <ChildItem
                    key={child.personUuid}
                    child={child}
                    onClick={() => handleOpenModal(child)}
                  />
                ))}
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                to="/sinistres/choix-police"
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal()}
              >
                {t('dashboard.police.beneficiaries.addBeneficiary')}
              </Button>
            </Grid>
          </Box>
        </Grid>
        <ChildrenModal
          open={popinOpen}
          onClose={handleCloseModal}
          child={selectedChild}
          onValidate={handleValidate}
        />
      </>
    )
  );
};

Beneficiaries.propTypes = {
  police: PropTypes.object.isRequired,
  scrollRef: PropTypes.object.isRequired,
  policyNumber: PropTypes.string.isRequired,
  beneficiaries: PropTypes.array,
  setBeneficiaries: PropTypes.func.isRequired,
};

export default Beneficiaries;
