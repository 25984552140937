const responseWithJson = (res) => {
  if (res.ok) return res.json();
  throw new Error(`${res.status} - ${res.statusText}`);
};

const diversApi = {
  async getIframeUrl() {
    const url = '/payment-url';

    const response = await fetch(url);
    return responseWithJson(response);
  },
  async getAvailableAddresses(query){
    const response = await fetch(
      `https://api-adresse.data.gouv.fr/search/?limit=15&q=${query}`
    );

    if(response.ok){
      const availableAddressesResponse = await response.json();
      return availableAddressesResponse.features;
    }
    throw { status : response.status };
  }
};

export default diversApi;
