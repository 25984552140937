import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { PnoContext } from 'contexts/pno';
import Popin from 'components/Popin';
import Field from 'components/Field';
import useStyles from './style';
import diversApi from 'api/diversApi';
import { useSnackbar } from 'notistack';
import { getMessageError } from 'constants/common/errorCode';

const AddressPopin = (props) => {
  const { showPopin, setShowPopin, automaticFieldTitle, fieldsObject } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState();
  const [loading, setIsLoading] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState();
  const [value, setValue] = useState(null);
  const [manual, setManual] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
  const { dispatch } = useContext(PnoContext);
  const { enqueueSnackbar } = useSnackbar();
  const [ errorDisplayed, setErrorDisplayed ] = useState(false);

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newFields = { ...fields };
    newFields[id] = value;
    setFields(newFields);
  };

  const handleChangeAutomaticField = (value) => {
    const { properties } = value;

    if (properties) {
      const address = {
        address1: properties.name,
        address2: '',
        zipCode: properties.postcode,
        city: properties.city,
      };

      setFields(address);
      setManual(true);
    }
  };

  const handleValidate = async () => {
    await dispatch({
      type: 'SET_DATA',
      payload: { key: fieldsObject, value: fields },
    });
    handleClosePopin();
  };

  const getOptionLabel = (option) => {
    if (option.properties && option.properties.label)
      return option.properties.label;

    return option;
  };

  const buttonOption = (
    <Button
      fullWidth
      onClick={(e) => {
        setManual(true);
        setOpen(false);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Typography variant="body1">
        {t('steps.addressStep.manuallySetAddress')}
      </Typography>
    </Button>
  );

  const getAvailableAddresses = async () => {
    const { length } = inputValue;
    if (length > 4) {
      await fetchAvailableAddresses();
    } else {
      setAvailableAddresses([]);
    }
  };

  const fetchAvailableAddresses = async () => {
    try {
      const availableAddressesResponse = await diversApi.getAvailableAddresses(inputValue);
      setAvailableAddresses(availableAddressesResponse);
    } catch (err) {
      setAvailableAddresses([]);
      if(!errorDisplayed){
        const messageError = err?.status === 400 ? 'common.autocomplete.adresse_input' : getMessageError(err);
        enqueueSnackbar(t(messageError), { variant: 'error', onClose: () => setErrorDisplayed(false)});
        setErrorDisplayed(true);
      }
    }
  }

  const isFormEmpty = () => {
    if (fields === {}) return true;
    if (!fields.address1 && !fields.address2 && !fields.city && !fields.zipCode)
      return true;
    if (
      fields.address1 === null &&
      !fields.address2 === null &&
      !fields.city === null &&
      !fields.zipCode === null
    )
      return true;
    if (
      fields.address1 === undefined &&
      !fields.address2 === undefined &&
      !fields.city === undefined &&
      !fields.zipCode === undefined
    )
      return true;
  };

  useEffect(() => {
    setIsLoading(true);
    getAvailableAddresses();
    setIsLoading(false);
  }, [inputValue]);

  useEffect(() => {
    setValidateButtonDisabled(isFormEmpty);
  }, [fields]);

  const renderAutomaticField = () => {
    return (
      <Autocomplete
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => getOptionLabel(option)}
        options={availableAddresses}
        filterOptions={() => {
          if (availableAddresses.length === 0) {
            return [buttonOption];
          }

          return availableAddresses;
        }}
        loadingText={t('common.loadingText')}
        loading={loading}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          setAvailableAddresses(
            newValue ? [newValue, ...availableAddresses] : availableAddresses
          );
          setValue(newValue);
          handleChangeAutomaticField(newValue);
        }}
        // PaperComponent={Link}
        renderInput={(params) => (
          <TextField
            {...params}
            label={automaticFieldTitle}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  };

  const renderManualFields = () => {
    return (
      <>
        <Field
          id="address1"
          onChange={handleChange}
          label={t('pno.addressStep.address')}
          type="text"
          value={fields.address1}
          variant="outlined"
          fullWidth
        />
        <Field
          id="address2"
          onChange={handleChange}
          label={t('pno.addressStep.additionalAddress')}
          type="text"
          value={fields.address2}
          variant="outlined"
          fullWidth
        />
        <Field
          id="zipCode"
          onChange={handleChange}
          label={t('pno.addressStep.zipCode')}
          type="text"
          value={fields.zipCode}
          variant="outlined"
          fullWidth
        />
        <Field
          id="city"
          onChange={handleChange}
          label={t('pno.addressStep.city')}
          type="text"
          value={fields.city}
          variant="outlined"
          fullWidth
        />
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.popinContentWrapper}>
        {manual ? (
          <>
            {renderManualFields()}
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={() => {
                setManual(false);
              }}
            >
              {t('steps.addressStep.automaticFindAddress')}
            </Button>
          </>
        ) : (
          renderAutomaticField()
        )}
      </div>
    );
  };

  return (
    <Popin
      open={showPopin}
      onClose={handleClosePopin}
      title={t('steps.addressStep.changeMyAddress')}
      content={renderContent()}
      validateButtonLabel={t('common.update')}
      showCancelButton
      onValidate={handleValidate}
      isValidateButtonDisabled={validateButtonDisabled}
    />
  );
};

AddressPopin.propTypes = {
  showPopin: PropTypes.bool,
  setShowPopin: PropTypes.func,
  automaticFieldTitle: PropTypes.string,
  fieldsObject: PropTypes.string,
};

AddressPopin.defaultProps = {
  showPopin: false,
};

export default AddressPopin;
