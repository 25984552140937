export const TYPE_PERSON_LINK_ENFANT = 'ENFANT';
export const TYPE_PERSON_LINK_COLOCATAIRE = 'COLOCATAIRE';
export const TYPE_PERSON_LINK_COASSURE = 'COASSURE';

export const RESIDENCE_TYPE_PRINCIPAL = 'P';
export const RESIDENCE_TYPE_SECONDAIRE = 'S';

export const QUITTANCE_NOT_PAID = 'A_REGLER';
export const QUITTANCE_PAID = 'REGLEE';
export const QUITTANCE_ANNUL = 'ANNULEE';
export const QUITTANCE_DELETED = 'SUPPRIMEE';
export const QUITTANCE_PENDING = 'EN ATTENTE DE VALIDATION';
export const QUITTANCE_IN_PROGESS = 'EN_COURS';

export const QUITTANCE_TYPE_PAID_MANUAL = 'M';
export const QUITTANCE_TYPE_PAID_AUTOMATIQUE = 'AUTO';

export const POLICE_MOUVEMENT_ANNP = 'ANNP';
export const POLICE_MOUVEMENT_RE = 'RE';
export const POLICE_MOUVEMENT_AN = 'AN';
export const POLICE_MOUVEMENT_RESEF = 'RESEF';

export const LANGUAGES = {
  en: 'en',
  fr: 'fr',
};

export const SOURCES = {
  PNO: 'pno',
  MRH: 'mrh',
};

export const TRANSLATE_MONTH = {
  1: 'common.monthLabel.january',
  2: 'common.monthLabel.february',
  3: 'common.monthLabel.march',
  4: 'common.monthLabel.april',
  5: 'common.monthLabel.may',
  6: 'common.monthLabel.june',
  7: 'common.monthLabel.july',
  8: 'common.monthLabel.august',
  9: 'common.monthLabel.september',
  10: 'common.monthLabel.october',
  11: 'common.monthLabel.november',
  12: 'common.monthLabel.december',
};

// 30 jours, normalement, la date d'effet pour un contrat hamon, c'est 40 jours après la date de souscription.
// On a 10 jours pour déclarer les informations Hamon, c'est pourquoi, on a encore 30 jours pour la date limite rappel
export const NB_DAYS_LIMIT_RAPPEL_HAMON = 30;

export const TYPE_POLICE_H = 'H';
export const TYPE_POLICE_PNO = 'DNO';
export const DOC_CONTRACTUEL = {
  IPID: { code: 'IPID', label: 'common.ipidPage' },
  CG: { code: 'CG', label: 'common.generalConditions' },
  NI: { code: 'NI', label: 'common.ni' },
};

export const MRH_CODE_RISQUE = 'HSMA';
export const PNO_CODE_RISQUE = 'PNODIGIT';

export const ZIP_CODE_NOT_ALLOWED = [
  { zipCode: '971', label: 'Martinque' },
  { zipCode: '972', label: 'Guadeloupe' },
  { zipCode: '973', label: 'Guyane' },
  { zipCode: '974', label: 'Réunion' },
  { zipCode: '975', label: 'Saint-Pierre-et-Miquelon' },
  { zipCode: '976', label: 'Mayotte' },
  { zipCode: '977', label: 'Saint-Barthélemy' },
  { zipCode: '978', label: 'Saint-Martin' },
  { zipCode: '984', label: 'Terres australes et antarctiques françaises' },
  { zipCode: '986', label: 'Wallis-et-Futuna' },
  { zipCode: '987', label: 'Polynésie française' },
  { zipCode: '988', label: 'Nouvelle-Calédonie' },
  { zipCode: '989', label: 'Île de Clipperton' },
];

export * from './bienType';
export * from './riskType';
export * from './errorCode';