import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils';
import useStyles from './style';
import {
  getPolicyStatus,
  policeStatuses as statuses,
} from 'views/Dashboard/Customer/PolicyPage/helper';
import { Box } from '@material-ui/core';

const PolicyStatus = (props) => {
  const { policy } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const policyStatus = getPolicyStatus(policy);

  const getStatusMessage = (status) => {
    const dateEffetResil = formatDate(policy.statusEffectiveDate, 'DD/MM/YYYY');
    const dateResil = formatDate(policy.statusDate, 'DD/MM/YYYY');

    switch (status) {
      case statuses.CANCELLED:
        return t('dashboard.police.statuses.cancelled') + dateResil;
      case statuses.ENDED:
        return t('dashboard.police.statuses.terminated') + dateEffetResil;
      case statuses.ENDED_FUTUR:
        return t('dashboard.police.statuses.toBeTerminated') + dateEffetResil;
      default:
        return '';
    }
  };

  return (
    <Box className={classes.boxAlert}>
      <Alert severity="info" className='alert'>
        { getStatusMessage(policyStatus) }
      </Alert>
    </Box>
  );
};

PolicyStatus.propTypes = {
  policy: PropTypes.shape().isRequired
};

export default PolicyStatus;
