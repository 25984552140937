import React, { useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { Box, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { POLICE_MOUVEMENT_RE } from 'constants';
import i18next from 'i18next';
import { LANGUAGES } from 'constants';

function BlocDateSurvenanceSin({
  date,
  setDate,
  police,
  dateError,
  setDateError,
}) {
  const { t } = useTranslation();
  const language = i18next.resolvedLanguage === LANGUAGES.en ? 'en-gb' : 'fr';
  moment.locale(language);
  const aujourdhui = moment().startOf('day');
  const today = moment().utc().format('YYYY/MM/DD');

  useEffect(() => {
    const isBetween = moment(date)
      .utc()
      .isBetween(
        moment(police.startDate).utc().format('YYYY/MM/DD'),
        today,
        'day',
        '[]'
      );

    if (!isBetween) {
      setDateError(getDateError(police.startDate, today));
    } else {
      setDateError(null);
    }
  }, [date]);

  const getDateError = (startDate, today) => {
    const diff = moment(startDate)
      .utc()
      .diff(moment(today).utc(), 'days', true);
    const formatedStartDate = moment(startDate).utc().format('DD/MM/YYYY');

    if (diff > 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_before_today'
      ).replace('%s', formatedStartDate);
    }
    if (diff < 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_between_startdate_today'
      ).replace('%s', formatedStartDate);
    }
    return t('dashboard.sinistre.form.error.date_declare_is_today');
  };

  const getDateMinDeDeclaration = () => {
    const dateEffetPolice = moment(police.startDate).startOf('day');
    const aujourdhuiMoins6Mois = moment().subtract(6, 'months').startOf('day');

    return moment.max(dateEffetPolice, aujourdhuiMoins6Mois);
  };

  const getDateMaxDeDeclaration = () => {
    const dateResil = moment(police.statusEffectiveDate).startOf('day');
    const resilieeDansLePassee =
      police.status.code.startsWith(POLICE_MOUVEMENT_RE) &&
      dateResil.isBefore(aujourdhui);

    if (resilieeDansLePassee) {
      return dateResil;
    }
    return aujourdhui;
  };

  return (
    <Box
      mb={6}
      display="flex"
      width={1}
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h5" color="secondary">
        {t('dashboard.sinistre.form.info.date')}
      </Typography>
      <Box mt={2} width={1}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
          <KeyboardDatePicker
            disableToolbar
            variant="inlined"
            inputVariant="filled"
            format="DD/MM/yyyy"
            className="date-picker-input"
            label={t('dashboard.sinistre.form.info.date')}
            value={date}
            onChange={(newDate) => {
              if (newDate) setDate(newDate.format('YYYY/MM/DD'));
              else setDate(null);
            }}
            minDate={getDateMinDeDeclaration()}
            maxDate={getDateMaxDeDeclaration()}
            style={{ width: '100%' }}
            maxDateMessage={null}
            minDateMessage={null}
            invalidDateMessage={t('error.fields.invalidDate')}
            autoOk
            cancelLabel=""
            okLabel=""
          />
        </MuiPickersUtilsProvider>
        {dateError && (
          <FormHelperText error={true} variant="outlined">
            {dateError}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

BlocDateSurvenanceSin.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  police: PropTypes.object.isRequired,
  dateError: PropTypes.string,
  setDateError: PropTypes.func.isRequired,
};

export default BlocDateSurvenanceSin;
